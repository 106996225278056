<template lang="pug">
  div
    TheHeader
    .wrapper.policy
      .policy_wrap
        h2 {{ $t('legalDocs.amlPolicyTitle') }}
        .policy_updated {{ $t('legalDocs.lastUpdated') }}: {{ updatedLegal(updatedAt, this.$i18n.locale) }}
        div(v-html="$t('legalDocs.amlPolicyFull')")
</template>

<script>
import TheHeader from '@/components/TheHeader';
import { updatedLegal } from '@/dates';

export default {
  components: { TheHeader },
  data() {
    return { updatedAt: '2024-03-12' };
  },
  methods: {
    updatedLegal(date, locale) {
      return updatedLegal(date, locale);
    }
  }
};
</script>

<style lang="scss" scoped>
  ul { padding-left: 40px !important; }

  .policy_wrap > div:not(.policy_updated) {
    font-weight: 700;
    font-size: 24px;
    margin-top: 40px;
  }
</style>
